<script setup>
import { useRouter } from "vue-router";
const router = useRouter();
const route = useRoute();
const chosenProject = useProject();
const props = defineProps({
  showSideMenu: { type: Boolean },
});

const { data: projects, refresh, pending } = await useFetch("/api/project");
if (route.params.id) {
  for (let project of projects.value) {
    if (project.id == route.params.id) {
      chosenProject.value = project.name;
    }
  }
}

let menuItems = ref([]);
if (projects.value) {
  menuItems.value = projects.value.map((item) => {
    return {
      name: item.name,
      id: item.id,
      isActive: false,
      isOpen: false,
      subItems: [
        {
          name: "Илэрц",
          icon: "/icons/search.svg",
          isActive: false,
        },
        {
          name: "Анализ",
          icon: "/icons/pie-chart.svg",
          isActive: false,
        },
        {
          name: "Эх сурвалж",
          icon: "/icons/globe.svg",
          isActive: false,
        },
        {
          name: "Хадгалсан илэрц",
          icon: "/icons/bookmark.svg",
          isActive: false,
        },
        {
          name: "Устгасан илэрц",
          icon: "/icons/trash.svg",
          isActive: false,
        },
        {
          name: "Тохиргоо",
          icon: "/icons/settings.svg",
          isActive: false,
        },
      ],
    };
  });
}

function setActiveItem(index, subItem, item) {
  // Reset all subItems
  menuItems.value.forEach((item) => {
    item.subItems.forEach((subItem) => {
      subItem.isActive = false;
    });
  });
  subItem.isActive = true;
  chosenProject.value = item.name;
  menuItems.value.forEach((item, i) => {
    if (i === index) {
      item.isActive = true;
      switch (subItem.name) {
        case "Тохиргоо":
          router.push({ path: `/project/${item.id}/settings` });
          break;
        case "Илэрц":
          router.push({ path: `/project/${item.id}/results` });
          break;
        case "Анализ":
          router.push({ path: `/project/${item.id}/analyze` });
          break;
        case "Эх сурвалж":
          router.push({ path: `/project/${item.id}/source` });
          break;
        case "Хадгалсан илэрц":
          router.push({ path: `/project/${item.id}/savedresults` });
          break;
        case "Устгасан илэрц":
          router.push({ path: `/project/${item.id}/deletedresults` });
          break;
      }
    } else {
      item.isActive = false;
    }
  });
}

function opener(item) {
  item.isOpen = !item.isOpen;
}
</script>

<template>
  <div class="sidemenu" :class="props.showSideMenu ? 'showsidemenu' : ''">
    <div>
      <img src="/images/kudos_logo_dark.png" alt="Kudos Logo" />
    </div>
    <div class="inlineIcons text-style-tokens-content-16-regular">
      <img src="/icons/settings.svg" />

      <img src="/images/company_logo.png" />

      <span> Sample LLC </span>
      <span class="spacer"></span>
      <img src="/icons/chevron-right.svg" />
    </div>
    <div style="padding: 12px 16px">
      <BaseChButton
        class="text-style-tokens-button-chip-14-medium"
        style="color: var(--color-neutral-700-aaa-text, #455068); width: 100%"
        design="outlined"
        state="neutral"
        iconHeight="16px"
        iconLeft="/plus-grey.svg"
        label="Төсөл нэмэх"
        href="/project"
      ></BaseChButton>
    </div>
    <div class="text-style-tokens-content-16-regular">
      <span> Төслүүд </span>
      <span class="spacer"></span>
      <span>{{ menuItems?.length }}/5</span>
    </div>
    <div class="navMenu text-style-tokens-content-16-regular">
      <div v-for="item in menuItems">
        <div
          class="inlineIcons"
          :class="item.isActive ? 'active' : ''"
          @click="opener(item)"
        >
          <img
            :src="
              item.isOpen
                ? '/icons/chevron-down.svg'
                : '/icons/chevron-right.svg'
            "
          />
          <img src="/icons/folder.svg" />
          {{ item.name }}
        </div>
        <div :class="item.isOpen ? 'open' : ''">
          <div
            v-for="subItem in item.subItems"
            @click="setActiveItem(menuItems.indexOf(item), subItem, item)"
            class="inlineIcons"
            :class="subItem.isActive ? 'active' : ''"
          >
            <img :src="subItem.icon" />
            {{ subItem.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="text-style-tokens-content-16-regular">
      <div class="inlineIcons">
        <img src="/icons/shuffle.svg" />
        Төсөл харьцуулалт
      </div>
      <div class="inlineIcons">
        <img src="/icons/help-circle.svg" />
        Тусламж
      </div>
    </div>
    <div class="spacer"></div>
    <div>
      <BaseChAvatar size="40" word="EC"></BaseChAvatar>
      <div>
        <span
          style="color: var(--color-neutral-900-aaa-text-darkest, #1d212d)"
          class="text-style-tokens-menu-item-14-regular"
        >
          Name
        </span>
        <span
          style="color: var(--color-neutral-600-aa-text, #5d6c87)"
          class="text-style-tokens-menu-item-12-regular"
        >
          name@domain.tld
        </span>
      </div>
    </div>
  </div>
</template>
