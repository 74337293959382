<script setup>
const chosenProject = useProject();
const props = defineProps({});
const route = useRoute();

// const projectName = computed(() => {
//   if (chosenProject.value == "") {
//     return;
//   }
//   return chosenProject.value;
// });

const pageTitle = computed(() => {
  if (route.fullPath.includes("settings")) {
    return "Төслийн тохиргоо";
  } else if (route.fullPath.includes("result")) {
    return "Илэрцүүд";
  } else if (route.fullPath.includes("analyze")) {
    return "Анализ";
  } else if (route.fullPath.includes("source")) {
    return "Эх сурвалж";
  } else if (route.fullPath.includes("saved")) {
    return "Хадгалсан илэрцүүд";
  } else if (route.fullPath.includes("deleted")) {
    return "Устгасан илэрц";
  } else if (route.fullPath.includes("profile")) {
    return "Профайл";
  } else if (route.fullPath.includes("deleted")) {
    return "Устгасан илэрц";
  } else {
  }
});
</script>
<template>
  <div class="header">
    <div class="text-container">
      <h6 class="text-style-header-h-6-20-semi-bold">
        {{ pageTitle }}
      </h6>
      <span class="text-style-tokens-label-12-medium">{{ chosenProject }}</span>
    </div>
  </div>
</template>
<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  padding: 16px 20px;
  border-bottom: 1px solid var(--color-neutral-300-border);
  background: var(--color-neutral-50);
  & .text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: fit-content;
    & h6 {
      color: var(--color-neutral-800-aaa-text-dark);
    }

    & span {
      color: var(--color-neutral-700-aaa-text);
    }
  }
}
</style>
