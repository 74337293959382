<template>
  <div class="container">
    <div
      class="menuOverlay"
      :class="showSideMenu ? 'show' : ''"
      @click="hideAll()"
    ></div>
    <div
      class="menuOverlay sidebarOverlay"
      :class="showSidebar ? 'show' : ''"
      @click="hideAll()"
    ></div>
    <NavigationSideMenu :showSideMenu="showSideMenu"></NavigationSideMenu>
    <NavigationHeader></NavigationHeader>
    <main class="main">
      <div class="toast-cont" :class="showToast ? 'show' : ''">
        <BaseChToast
          icon="close"
          customButton="hello"
          :customButtonAction="hello()"
          dismissable
          :state="toast.state"
          @close="showToast = false"
        >
          {{ toast.text }}</BaseChToast
        >
      </div>
      <slot />
    </main>
    <!-- <div class="sidebar" :class="showSidebar ? 'showsidebar' : ''"></div> -->
  </div>
</template>
<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";

const showSideMenu = ref(false);
const showSidebar = ref(false);

const showToast = ref(false);

function hello() {
  console.log("hehelo");
}

const toast = useToast();
watch(toast, (val) => {
  showToast.value = true;
  setTimeout(() => {
    showToast.value = false;
  }, "2000");
});
const router = useRouter();

function hideAll() {
  showSideMenu.value = false;
  showSidebar.value = false;
}

function toggleSideMenu() {
  showSidebar.value = false;
  showSideMenu.value = !showSideMenu.value;
}
function toggleSidebar() {
  showSideMenu.value = false;
  showSidebar.value = !showSidebar.value;
}
</script>

<style lang="scss">
html,
body {
  // background: var(--color-neutral-100-light-bg, #f5f8fa);
  height: 100%;
  overflow-y: hidden;

  #__nuxt,
  #__nuxt > div {
    height: 100%;
  }
  *::-webkit-scrollbar {
    width: 14px;
  }

  *::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
    border: solid 4px transparent;
  }

  *::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px rgba(196, 196, 196, 1);
    border: solid 4px transparent;
    -webkit-border-radius: 14px;
  }
}

.toast-cont {
  top: -100px; /* Start hidden above the viewport */
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  margin: 0 auto;
  transition: top 0.5s ease-in-out; /* Smooth animation */
  z-index: 999; /* Ensure it's above other content */
}
.toast-cont.show {
  top: 8px;
}

.container {
  max-width: 1920px;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas:
    "sidemenu header header"
    "sidemenu main main";
  grid-template-columns: 360px 1fr;
  grid-template-rows: 80px 1fr;
  z-index: 500;
  transition: all 0.2s;
  background-color: var(--color-neutral-100-light-bg, #f5f8fa);

  .menuOverlay {
    grid-column: 1/-1;
    grid-row: 1/-1;
    background: var(--color-neutral-100-light-bg, #f5f8fa);
    z-index: 750;
    display: none;
  }

  .sidebarOverlay {
    grid-column: 1/-1;
    grid-row: 2/-1;
  }

  .menuOverlay.show,
  .sidebarOverlay.show {
    display: block;
    background-color: var(--color-modal-overlay, rgba(29, 33, 45, 0.5));
  }

  .sidebar {
    grid-area: sidebar;
    background-color: lightblue;
  }

  .header {
    grid-area: header;
    // background-color: aqua;
  }
  .sidemenu {
    grid-area: sidemenu;
    display: flex;
    padding: 0px 12px 32px 12px;

    flex-direction: column;
    gap: 8px;
    flex-shrink: 0;
    border-right: 1px solid var(--color-neutral-300-border, #d3dfe7);
    background: var(--color-neutral-50, #fbfcfe);

    color: var(--color-neutral-600-aa-text, #5d6c87);
    font-variant-numeric: lining-nums tabular-nums;

    /* Tokens/Content/16/Regular */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.18px;

    > .navMenu {
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding-bottom: 16px;
      border-bottom: 1px solid var(--color-neutral-300-border, #d3dfe7);

      > div > div:first-child {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 8px 16px;
        align-self: stretch;
        border-radius: 4px;
      }

      > div > div:first-child.active,
      > div > div:nth-child(2) > div.active {
        background: var(--color-primary-100-light-bg, #eff9fe);
        color: var(--color-primary-700-aaa-colorful, #0e3ed0);
        font-variant-numeric: lining-nums tabular-nums;
        &:hover {
          color: var(--color-primary-700-aaa-colorful, #0e3ed0);
        }
        > img {
          filter: brightness(0) saturate(100%) invert(41%) sepia(86%)
            saturate(1451%) hue-rotate(199deg) brightness(97%) contrast(96%);
        }
      }

      > div > div:first-child:hover,
      > div > div:nth-child(2) > div:hover {
        background: var(--color-neutral-200-darker-bg-light-border, #e9f0f4);
        color: var(--color-neutral-900-aaa-text-darkest, #1d212d);
        font-variant-numeric: lining-nums tabular-nums;
      }

      > div > div:nth-child(2) {
        animation: hideMenu 0.2s linear forwards;

        flex-direction: column;
        transform-origin: top;
        perspective: 1000px;
        transform-style: preserve-3d;
        > div {
          display: flex;
          cursor: pointer;
          padding: 8px 16px 8px 44px;
        }
      }

      > div > div:nth-child(2).open {
        // Play animation
        display: flex;
        animation: showMenu 0.2s linear forwards;
      }
    }

    // Animation to open/close submenus using scaleY and hide at the end
    @keyframes showMenu {
      from {
        transform: scaleY(0);
        height: 0;
      }
      to {
        transform: scaleY(1);
        height: 240px;
      }
    }

    @keyframes hideMenu {
      from {
        height: 240px;
        transform: scaleY(1);
      }
      to {
        height: 0;
        transform: scaleY(0);
        display: none;
      }
    }

    // Logo
    > div:first-child {
      display: flex;
      padding: 24px 0px 24px 16px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      align-self: stretch;
    }

    .inlineIcons {
      display: flex;
      align-items: center;
      gap: 8px;
      > img {
        width: 20px;
        height: 20px;
      }
    }

    // Items with inline icons
    > div:nth-child(2),
    > div:nth-child(4) {
      display: flex;
      padding: 8px 16px;
    }

    > div:nth-child(6) {
      margin-top: 8px;
      div {
        white-space: nowrap;
        padding: 8px 16px 8px 44px;
      }
    }

    > div:last-child {
      display: flex;
      height: 55px;
      padding: 8px 16px 8px 16px;
      align-items: center;
      gap: 12px;
      align-self: stretch;
      > div:first-child {
        border: 2px solid var(--color-neutral-300-border, #d3dfe7);
      }
      > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        > span:first-child {
          color: var(--color-neutral-900-aaa-text-darkest, #1d212d);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 171.429% */
          letter-spacing: -0.09px;
        }

        > span:last-child {
          color: var(--color-neutral-600-aa-text, #5d6c87);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
        }
      }
    }
  }
  .main {
    position: relative;
    overflow-y: auto;
    grid-area: main;
    // background-color: lightgreen;
  }
  //   ::-webkit-scrollbar {
  //     width: 10px;
  // }

  // ::-webkit-scrollbar-track {
  //     box-shadow: inset 0 0 10px 10px green;
  //     border: solid 3px transparent;
  // }

  // ::-webkit-scrollbar-thumb {
  //     box-shadow: inset 0 0 10px 10px red;
  //     border: solid 3px transparent;
  // }

  // .main::-webkit-scrollbar {
  //   width: 14px;
  // }

  // .main::-webkit-scrollbar-track {
  //   box-shadow: inset 0 0 10px 10px transparent;
  //   border: solid 4px transparent;
  // }

  // .main::-webkit-scrollbar-thumb {
  //   box-shadow: inset 0 0 10px 10px rgba(196, 196, 196, 1);
  //   border: solid 4px transparent;
  //   -webkit-border-radius: 14px;
  // }
}

@media screen and (max-width: 1440px) {
  .container {
    grid-template-columns: 260px 1fr;
  }
}

@media screen and (max-width: 1280px) {
  .container {
    transition: 0.3s ease-in-out;
    grid-template-areas:
      "header header header"
      "main main sidebar";
    grid-row: 80px 1fr;

    .sidemenu {
      grid-column: 1/2;
      grid-row: 1/-1;
      z-index: 1000;
      transform: translateX(-100%);
      transition: 0.3s ease-in-out;
    }
    .sidemenu.showsidemenu {
      transform: translateX(0);
    }
  }
}

@media screen and (max-width: 1024px) {
  .container {
    grid-template-areas:
      "header header header"
      "main main main";

    .sidebar {
      transform: translateX(100%);
      transition: 0.3s ease-in-out;
      z-index: 1000;
      grid-column: -1/-2;
      grid-row: 2/-1;
    }

    .sidebar.showsidebar {
      transform: translateX(0);
    }
  }
}

@media screen and (min-width: 1024px) {
  .container {
    .sidebarOverlay.show {
      display: none;
    }
  }
}

// @media screen and (min-width: 1280px) {
//   .container {
//     .menuOverlay.show {
//       display: none;
//     }
//   }
// }

.spacer {
  flex: 1;
}

.tab-body-cont {
  padding: 20px;
  width: 100%;
  height: 100%;
}
</style>

<!-- z-index -->
